// IMPORTS
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../helpers/firebase";
import { setItems, setEditor } from "../helpers/redux";
import { gridRows, gridColumns } from "../helpers/variables";
// COMPONENT
const Editor = ({ selectDays, generateItems }) => {
  // STATE
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const days = useSelector((state) => state.days);
  const items = useSelector((state) => state.items);
  const editor = useSelector((state) => state.editor);
  // LIFE CYCLE
  useEffect(() => {
    dispatch(
      setEditor(
        {
          tasks: user.tasks.length > 0 ? true : false,
          goals: user.goals.length > 0 ? true : false,
          milestones: user.milestones.length > 0 ? true : false,
        },
        true
      )
    );
  }, [dispatch, user]);
  // GENERATE BUTTON
  const generateButton = (type) => {
    return (
      <div
        className="editorButton"
        onClick={() => {
          // TOGGLE MANAGE
          if (type === "new" || type === "manage") {
            dispatch(
              setEditor({
                ...editor,
                manage: {
                  ...editor.manage,
                  [editor.type]: !editor.manage[editor.type],
                },
              })
            );
          } else if (type === "delete") {
            const updatedItems = user[editor.type].filter(
              (currentItem) =>
                currentItem.id !== items.selected.editor[editor.type]
            );
            const updatedTrash = user.trash;
            updatedTrash.push(items.edited[editor.type]);
            db.collection("users")
              .doc(user.id)
              .update({
                [editor.type]: updatedItems,
                trash: updatedTrash,
              })
              .catch((error) => {
                console.log(
                  `${editor.type.toUpperCase()} DELETE ERROR >>`,
                  error
                );
              });
            selectDays("editor reset", [], true);
            updatedItems.length === 0 &&
              dispatch(
                setEditor({
                  ...editor,
                  manage: {
                    ...editor.manage,
                    [editor.type]: false,
                  },
                })
              );
          } else if (type === "save") {
            const updatedItems = editor.manage[editor.type]
              ? user[editor.type].filter(
                  (currentItem) =>
                    currentItem.id !== items.selected.editor[editor.type]
                )
              : user[editor.type];
            updatedItems.push(
              editor.manage[editor.type]
                ? items.edited[editor.type]
                : items.new[editor.type]
            );
            db.collection("users")
              .doc(user.id)
              .update({
                [editor.type]: updatedItems,
              })
              .catch((error) => {
                console.log(
                  `${editor.type.toUpperCase()} SAVE ERROR >>`,
                  error
                );
              });
            selectDays("editor reset", [], true);
            !editor.manage[editor.type] &&
              dispatch(
                setEditor({
                  ...editor,
                  manage: {
                    ...editor.manage,
                    [editor.type]: true,
                  },
                })
              );
          }
        }}
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </div>
    );
  };
  // GENERATE SELECTOR
  const generateSelector = (addOrRemove, addOrRemoveAll, numbersInside) => (
    <div
      className="editorDaysSelector"
      onClick={() => {
        const itemsField = editor.manage[editor.type] ? "edited" : "new";
        selectDays(
          "editor",
          addOrRemove
            ? [
                ...new Set([
                  ...items[itemsField][editor.type].days.assigned,
                  ...numbersInside,
                ]),
              ]
            : items[itemsField][editor.type].days.assigned.filter(
                (currentDay) => !numbersInside.includes(currentDay)
              ),
          true
        );
      }}
    >
      {`${addOrRemove ? "+" : "-"}${addOrRemoveAll ? " All" : ""}`}
    </div>
  );
  //  GENERATE ROW SELECTORS
  const generateRowSelectors = () => {
    let generatedRows = [];
    gridRows.map((row) => {
      return generatedRows.push(
        <div key={row.number} className="editorDaysRowSelectorContainerSmall">
          {generateSelector(true, false, row.numbersInside)}
          {generateSelector(false, false, row.numbersInside)}
        </div>
      );
    });
    return generatedRows;
  };
  // GENERATE COLUMN SELECTORS
  const generateColumnSelectors = () => {
    let generatedColumns = [];
    gridColumns.map((column) => {
      return generatedColumns.push(
        <div
          key={column.number}
          className="editorDaysColumnSelectorContainerSmall"
        >
          {generateSelector(true, false, column.numbersInside)}
          {generateSelector(false, false, column.numbersInside)}
        </div>
      );
    });
    return generatedColumns;
  };
  // RENDER
  return (
    <div
      className="editorContainer"
      style={{
        display: editor.show ? "flex" : "none",
      }}
    >
      {/* MANAGE CONTAINER */}
      <div
        className="editorManageContainer"
        style={{ display: editor.manage[editor.type] ? "flex" : "none" }}
      >
        <div className="editorManager">
          {generateItems(editor.type, null, "editor", true)}
        </div>
      </div>
      {/* INPUTS CONTAINER */}
      <div
        className="editorInputsContainer"
        style={{ display: !editor.manage[editor.type] ? "flex" : "none" }}
      >
        <input
          className="editorInput"
          type="text"
          placeholder="Title"
          autoComplete="off"
          value={items.new[editor.type].title}
          onChange={(event) => {
            dispatch(
              setItems({
                ...items,
                new: {
                  ...items.new,
                  [editor.type]: {
                    ...items.new[editor.type],
                    title: event.target.value,
                  },
                },
              })
            );
          }}
        />
        <textarea
          className="editorTextArea"
          type="text"
          placeholder="Description"
          autoComplete="off"
          value={items.new[editor.type].description}
          onChange={(event) => {
            dispatch(
              setItems({
                ...items,
                new: {
                  ...items.new,
                  [editor.type]: {
                    ...items.new[editor.type],
                    description: event.target.value,
                  },
                },
              })
            );
          }}
        />
      </div>
      {/* DAYS SELECT CONTAINER */}
      <div className="editorDaysContainer">
        <div className="editorDays">
          {days.all.map((day, key) => {
            const itemType = editor.manage[editor.type] ? "edited" : "new";
            return (
              <div
                key={key}
                className={
                  items[itemType][editor.type].days.assigned.includes(day.value)
                    ? "editorDaySelected"
                    : "editorDay"
                }
                onClick={() => {
                  if (day.selected) {
                    selectDays(
                      "editor",
                      editor.manage[editor.type]
                        ? items.edited[editor.type].days.assigned.filter(
                            (currentDay) => currentDay !== day.value
                          )
                        : items.new[editor.type].days.assigned.filter(
                            (currentDay) => currentDay !== day.value
                          ),
                      true
                    );
                  } else {
                    selectDays(
                      "editor",
                      editor.manage[editor.type]
                        ? [
                            ...items.edited[editor.type].days.assigned,
                            day.value,
                          ]
                        : [...items.new[editor.type].days.assigned, day.value],
                      true
                    );
                  }
                }}
              >
                <p className="editorDayText">
                  {items[itemType][editor.type].days.assigned.includes(
                    day.value
                  ) && <span className="editorDayX">x</span>}
                  {day.value}
                </p>
              </div>
            );
          })}
        </div>
        <div className="editorDaysRowSelectorContainerLarge">
          {generateRowSelectors()}
        </div>
        <div className="editorDaysColumnSelectorContainerLarge">
          {generateColumnSelectors()}
        </div>
        <div className="editorDaysAllSelectorContainer">
          {generateSelector(
            true,
            true,
            Array.from(Array(30), (_, x) => x + 1)
          )}
          {generateSelector(
            false,
            true,
            Array.from(Array(30), (_, x) => x + 1)
          )}
        </div>
      </div>
      {/* BUTTONS CONTAINER */}
      <div className="editorButtonsContainer">
        {user[editor.type].length > 0 &&
          (editor.manage[editor.type]
            ? generateButton("new")
            : generateButton("manage"))}
        {editor.manage[editor.type] &&
          items.selected.editor[editor.type] !== "" &&
          generateButton("delete")}
        {(items.selected.editor[editor.type] !== "" ||
          !editor.manage[editor.type]) &&
          generateButton("save")}
      </div>
    </div>
  );
};
// EXPORT
export default Editor;
