// IMPORTS
import React, { useContext, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaClipboardList } from "react-icons/fa";
import { setDays, setItems } from "../helpers/redux";
import { itemTemplate } from "../helpers/variables";
import Editor from "../components/Editor";
import Header from "../components/Header";
import Settings from "../components/Settings";
import { Styles } from "../index";
const Item = lazy(() => import("../components/Item"));
// COMPONENT
const Calendar = () => {
  // STATE
  const dispatch = useDispatch();
  const userStyles = useContext(Styles);
  const user = useSelector((state) => state.user);
  const editor = useSelector((state) => state.editor);
  const days = useSelector((state) => state.days);
  const items = useSelector((state) => state.items);
  // SELECT DAYS
  const selectDays = (selectFor, daysArray, updateItems) => {
    dispatch(
      setDays({
        selected: selectFor === "calendar" ? daysArray[0] : days.selected,
        all:
          selectFor === "editor" || selectFor === "editor reset"
            ? days.all.filter((currentDay) => {
                currentDay.selected = daysArray.includes(currentDay.value);
                return currentDay;
              })
            : days.all,
      })
    );
    if (updateItems) {
      dispatch(
        setItems({
          ...items,
          selected: {
            ...items.selected,
            editor: {
              ...items.selected.editor,
              [editor.type]:
                selectFor === "editor reset"
                  ? ""
                  : items.selected.editor[editor.type],
            },
          },
          edited: {
            ...items.edited,
            [editor.type]:
              selectFor === "editor reset"
                ? editor.manage[editor.type]
                  ? itemTemplate(editor.type)
                  : items.edited[editor.type]
                : {
                    ...items.edited[editor.type],
                    days: {
                      ...items.edited[editor.type].days,
                      assigned: editor.manage[editor.type]
                        ? daysArray
                        : items.edited[editor.type].days.assigned,
                    },
                  },
          },
          new: {
            ...items.new,
            [editor.type]:
              selectFor === "editor reset"
                ? editor.manage[editor.type]
                  ? items.new[editor.type]
                  : itemTemplate(editor.type)
                : {
                    ...items.new[editor.type],
                    days: {
                      ...items.new[editor.type].days,
                      assigned: editor.manage[editor.type]
                        ? items.new[editor.type].days.assigned
                        : daysArray,
                    },
                  },
          },
        })
      );
    }
  };
  // GENERATE ITEMS
  const generateItems = (type, completed, day, showItems) => {
    const currentItemsArray =
      day === "editor" || day === "trash"
        ? user[type]
        : user[type].filter(
            (item) =>
              item.days.assigned.includes(day) &&
              (completed
                ? item.days.completed.includes(day)
                : !item.days.completed.includes(day))
          );
    return showItems ? (
      currentItemsArray.map((item) => (
        <Suspense key={item.id} fallback={<div />}>
          <Item item={item} day={day} selectDays={selectDays} />
        </Suspense>
      ))
    ) : (
      <p className="calendarDayItemsNumber">{currentItemsArray.length}</p>
    );
  };
  // RENDER
  return (
    <div className="calendarContainer">
      {/* HEADER */}
      <Header headerType={"calendar"} />
      {/* EDITOR */}
      <Editor selectDays={selectDays} generateItems={generateItems} />
      {/* SETTINGS */}
      <Settings generateItems={generateItems} />
      {/* CALENDAR */}
      <div className="calendarDaysContainer">
        {days.all.map((day, key) => {
          let column = day.column;
          let row = day.row;
          let daySelected = days.selected === day.value;
          return (
            // CALENDAR DAY
            <div
              key={key}
              className={daySelected ? "calendarDaySelected" : "calendarDay"}
              style={{
                top:
                  row === 1
                    ? ".8%"
                    : row === 2
                    ? "17.47%"
                    : row === 3
                    ? "34.14%"
                    : row === 4
                    ? "50.8%"
                    : row === 5
                    ? "67.48%"
                    : row === 6 && "84.15%",
                left:
                  column === 1
                    ? ".5%"
                    : column === 2
                    ? "20.5%"
                    : column === 3
                    ? "40.5%"
                    : column === 4
                    ? "60.5%"
                    : column === 5 && "80.5%",
              }}
              onClick={() => {
                !daySelected && selectDays("calendar", [day.value], true);
              }}
            >
              {/* CLOSE BUTTON */}
              {daySelected && (
                <div
                  className="calendarDayButton"
                  onClick={() => {
                    selectDays("calendar", [], true);
                  }}
                >
                  X
                </div>
              )}
              {/* HEADER */}
              <div
                className="calendarDaySection"
                style={{
                  background: userStyles["--firstColor"],
                  borderBottom: `1px solid ${userStyles["--fontColor"]}`,
                }}
              >
                <p className="calendarDayNumber">{day.value}</p>
                <p className="calendarDayText">
                  {daySelected ? (
                    "Todo"
                  ) : window.innerWidth < 1250 ? (
                    <FaClipboardList />
                  ) : (
                    "Todo"
                  )}
                </p>
                <p className="calendarDayText">
                  {daySelected ? (
                    "Completed"
                  ) : window.innerWidth < 1250 ? (
                    <FaCheck />
                  ) : (
                    "Completed"
                  )}
                </p>
              </div>
              {/* TYPES */}
              {["tasks", "goals", "milestones"].map((type, key) => {
                let typeColor = `--${type}Color`;
                return (
                  <div
                    key={key}
                    className="calendarDaySection"
                    style={{
                      borderBottom:
                        type !== "milestones" &&
                        `1px solid ${userStyles["--fontColor"]}`,
                    }}
                  >
                    <p
                      className="calendarDayText"
                      style={{
                        background: userStyles["--firstColor"],
                        textDecoration:
                          daySelected && `underline ${userStyles[typeColor]}`,
                      }}
                    >
                      {daySelected
                        ? type
                        : window.innerWidth < 1250
                        ? type.charAt(0)
                        : type}
                    </p>
                    <div
                      className="calendarDayItems"
                      style={{
                        borderLeft: `1px solid ${userStyles["--fontColor"]}`,
                        borderRight: `1px solid ${userStyles["--fontColor"]}`,
                      }}
                    >
                      {generateItems(type, false, day.value, daySelected)}
                    </div>
                    <div className="calendarDayItems">
                      {generateItems(type, true, day.value, daySelected)}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
// EXPORT
export default Calendar;
