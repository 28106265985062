// IMPORTS
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../helpers/firebase";
import { setEditor } from "../helpers/redux";
import { Styles } from "../index";
// COMPONENT
const Header = ({ headerType }) => {
  // STATE
  const dispatch = useDispatch();
  const userStyles = useContext(Styles);
  const editor = useSelector((state) => state.editor);
  const calendarOptions = ["tasks", "goals", "milestones", "settings"];
  const settingsOptions = ["background", "colors", "trash", "sign out"];
  // GENERATE CALENDAR BUTTON
  const generateCalendarButton = (type) => {
    return (
      <div
        key={type}
        className={
          type === "settings"
            ? editor.settings.show
              ? "headerButtonSelected"
              : "headerButton"
            : editor.show & (editor.type === type)
            ? "headerButtonSelected"
            : "headerButton"
        }
        style={{
          width: `calc(100% / ${calendarOptions.length})`,
          background: userStyles["--secondColor"],
          borderRight: type === "settings" && "none",
        }}
        onClick={() => {
          dispatch(
            setEditor({
              ...editor,
              show:
                type === "settings"
                  ? false
                  : editor.show & (editor.type === type)
                  ? false
                  : true,
              type: type === "settings" ? editor.type : type,
              settings: {
                ...editor.settings,
                show: type === "settings" ? !editor.settings.show : false,
              },
            })
          );
        }}
      >
        <p className="headerButtonText">
          <span
            className="headerButtonX"
            style={{
              display:
                type === "settings"
                  ? editor.settings.show
                    ? "flex"
                    : "none"
                  : editor.show & (editor.type === type)
                  ? "flex"
                  : "none",
            }}
          >
            x
          </span>
          {type}
        </p>
      </div>
    );
  };
  // GENERATE SETTINGS BUTTON
  const generateSettingsButton = (type) => {
    return (
      <div
        key={type}
        className={
          editor.settings.show & (editor.settings.type === type)
            ? "headerButtonSelected"
            : "headerButton"
        }
        style={{
          width: `calc(100% / ${settingsOptions.length}`,
          background: userStyles["--firstColor"],
          borderRight: type === "sign out" && "none",
        }}
        onClick={() => {
          if (type === "sign out") {
            auth.signOut();
          } else {
            dispatch(
              setEditor({
                ...editor,
                show: false,
                settings: {
                  ...editor.settings,
                  type: type,
                },
              })
            );
          }
        }}
      >
        <p className="headerButtonText">{type}</p>
      </div>
    );
  };
  // RENDER
  return (
    <div className="headerContainer">
      {headerType === "calendar"
        ? calendarOptions.map((option) => generateCalendarButton(option))
        : settingsOptions.map((option) => generateSettingsButton(option))}
    </div>
  );
};
// EXPORT
export default Header;
