// IMPORTS
import React, { Fragment, useState } from "react";
import { FaGoogle, FaFacebook, FaTwitter, FaGithub } from "react-icons/fa";
import { auth, google, facebook, twitter, github } from "../helpers/firebase";
import UserIcon from "../assets/icons/user.png";
import PasswordIcon from "../assets/icons/password.png";
import css from "../styles/modules/_exports.scss";
// COMPONENT
const Login = () => {
  // STATE
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [popUp, setPopUp] = useState({
    showPopUp: false,
    reset: false,
    resetFailed: false,
    message: "",
  });
  const { showPopUp, reset, resetFailed, message } = popUp;
  // HANDLE ERROR
  const handleError = (error) => {
    const { code, message } = error;
    setPopUp({
      ...popUp,
      showPopUp: true,
      message:
        code === "auth/user-not-found"
          ? `User ${email} does not exist.`
          : code === "auth/invalid-email"
          ? "Your email address is pourly formated."
          : code === "auth/email-already-in-use"
          ? "An account with this email already exists"
          : code === "auth/wrong-password"
          ? "Your password is incorrect."
          : code === "auth/weak-password"
          ? "Your password must be atleast 6 characters long."
          : code === "missing-email"
          ? "Please enter your email address."
          : code === "missing-password"
          ? "Please enter your password."
          : message.split(":")[1].split("(")[0],
    });
    return false;
  };
  // HANDLE SUBMIT
  const handleSubmit = ({ type, value }) => {
    if (type === "Sign In" || type === "Sign Up") {
      if (email === "") {
        return handleError({ code: "missing-email" });
      }
      if (password === "") {
        return handleError({ code: "missing-password" });
      }
    }
    switch (type) {
      case "Sign In":
        return auth
          .signInWithEmailAndPassword(email, password)
          .catch((error) => handleError(error));
      case "Sign Up":
        return auth
          .createUserWithEmailAndPassword(email, password)
          .catch((error) => handleError(error));
      case "Reset Password":
        return auth
          .sendPasswordResetEmail(email)
          .then(() => {
            console.log("SENT");
            setPopUp({
              showPopUp: true,
              reset: false,
              resetFailed: false,
              message: `Password reset email sent to ${email}`,
            });
          })
          .catch((error) => {
            console.log("NOT SENT");
            setPopUp({
              showPopUp: true,
              reset: false,
              resetFailed: true,
              message: error.message.split(":")[1].split("(")[0],
            });
          });
      case "Provider":
        return auth.signInWithPopup(value).catch((error) => handleError(error));
      default:
        break;
    }
  };
  // GENERATE INPUT
  const generateInput = (type) => {
    return (
      <form className="loginInputContainer">
        <label className="loginInputLabel" htmlFor={type}>
          <img
            className="loginInputImage"
            alt={`${type} icon`}
            src={
              type === "email" ? UserIcon : type === "password" && PasswordIcon
            }
          />
        </label>
        <input
          className="loginInput"
          id={type}
          type={
            type === "password" ? (showPassword ? "text" : "password") : "text"
          }
          placeholder={type}
          autoComplete="off"
          value={type === "email" ? email : type === "password" && password}
          onChange={(event) => {
            type === "email"
              ? setEmail(event.target.value)
              : type === "password" && setPassword(event.target.value);
          }}
        />
        {type === "password" && (
          <div className="loginInputButtonsContainer">
            <div
              className="loginInputButton"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? "hide" : "show"}
            </div>
            <div
              className="loginInputButton"
              onClick={() => {
                setPopUp({
                  showPopUp: true,
                  reset: true,
                  resetFailed: false,
                  message: "",
                });
              }}
            >
              reset
            </div>
          </div>
        )}
      </form>
    );
  };
  // GENERATE POP UP
  const generatePopUp = () => {
    return (
      <div className="loginContainer">
        {/* CLOSE BUTTON */}
        <div
          className="loginCloseButton"
          onClick={() => {
            if (resetFailed) {
              setPopUp({
                showPopUp: true,
                reset: true,
                resetFailed: false,
                message: "",
              });
            } else {
              if (message === `Password reset email sent to ${email}`) {
                setEmail("");
              }
              setPopUp({
                showPopUp: false,
                reset: false,
                resetFailed: false,
                message: "",
              });
            }
          }}
        >
          X
        </div>
        {/* PASSWORD RESET */}
        {reset ? (
          <Fragment>
            <p className="loginPopUpTitle">Password Reset</p>
            {generateInput("email")}
            <div
              className="loginSubmitButton"
              style={{ width: "auto" }}
              onClick={() => {
                if (email !== "") {
                  handleSubmit({ type: "Reset Password", value: null });
                }
              }}
            >
              Reset Password
            </div>
          </Fragment>
        ) : (
          // MESSAGE
          <p className="loginPopUpText">{message}</p>
        )}
      </div>
    );
  };
  // GENERATE FORM
  const generateForm = () => {
    return (
      <div className="loginContainer">
        {/* HEADER */}
        <div className="loginHeader">
          <div
            className="loginHeaderButton"
            style={{ textDecoration: signingIn ? "underline" : "none" }}
            onClick={() => {
              setSigningIn(true);
            }}
          >
            Sign In
          </div>
          <div
            className="loginHeaderButton"
            style={{ textDecoration: signingIn ? "none" : "underline" }}
            onClick={() => {
              setSigningIn(false);
            }}
          >
            Sign Up
          </div>
        </div>
        {/* INPUTS */}
        {generateInput("email")}
        {generateInput("password")}
        <div className="loginButtonsContainer">
          {/* SUBMIT BUTTON */}
          <div
            className="loginSubmitButton"
            onClick={() => {
              if (signingIn) {
                handleSubmit({ type: "Sign In", value: null });
              } else {
                handleSubmit({ type: "Sign Up", value: null });
              }
            }}
          >
            {signingIn ? "Sign In" : "Sign Up"}
          </div>
          {/* PROVIDER BUTTONS */}
          {[
            { name: "google", icon: <FaGoogle />, provider: google },
            { name: "facebook", icon: <FaFacebook />, provider: facebook },
            { name: "twitter", icon: <FaTwitter />, provider: twitter },
            { name: "github", icon: <FaGithub />, provider: github },
          ].map(({ name, icon, provider }, index) => {
            return (
              <button
                key={index}
                className="loginProviderButton"
                onClick={() => {
                  handleSubmit({ type: "Provider", value: provider });
                }}
              >
                <p
                  className="loginProviderButtonIcon"
                  style={{ color: css[`${name}Color`] }}
                >
                  {icon}
                </p>
                <p className="loginProviderButtonText">{name}</p>
              </button>
            );
          })}
        </div>
      </div>
    );
  };
  // RENDER
  return showPopUp ? generatePopUp() : generateForm();
};
// EXPORT
export default Login;
