// IMPORTS
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// CONFIG
const firebaseConfig = {
  apiKey: "AIzaSyAxT_FvyBG0fMpN_YIJsJvelNQTBpS6hso",
  authDomain: "daytracker-9fb68.firebaseapp.com",
  databaseURL: "https://daytracker-9fb68.firebaseio.com",
  projectId: "daytracker-9fb68",
  storageBucket: "daytracker-9fb68.appspot.com",
  messagingSenderId: "1091580528798",
  appId: "1:1091580528798:web:25428572429292b752538f",
  measurementId: "G-LSH6FGZH64",
};
// INITIALIZE
firebase.initializeApp(firebaseConfig);
// EXPORTS
export const auth = firebase.auth();
export const google = new firebase.auth.GoogleAuthProvider();
export const facebook = new firebase.auth.FacebookAuthProvider();
export const twitter = new firebase.auth.TwitterAuthProvider();
export const github = new firebase.auth.GithubAuthProvider();
export const storage = firebase.storage();
export const db = firebase.firestore();
export default firebase;
