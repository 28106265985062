// IMPORTS
import "./styles/App.scss";
import React, { createContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector, Provider } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./helpers/firebase";
import { store, setUser, setEditor } from "./helpers/redux";
import { userTemplate, editorTemplate, hexToRGBA } from "./helpers/variables";
import Calendar from "./pages/Calendar";
import Login from "./pages/Login";
import css from "./styles/modules/_exports.scss";
export const Styles = createContext();
// COMPONENT
const App = () => {
  // STATE
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [currentUser] = useAuthState(auth);
  // LIFE CYCLE
  useEffect(() => {
    let unsubscribe;
    if (currentUser) {
      const ref = db.collection("users").doc(currentUser.uid);
      unsubscribe = ref.onSnapshot((doc) => {
        doc.exists
          ? dispatch(setUser(doc.data()))
          : ref.set(userTemplate(currentUser.uid, currentUser.displayName));
      });
    } else {
      dispatch(setUser(userTemplate(null, null)));
      dispatch(setEditor(editorTemplate));
    }
    return unsubscribe;
  }, [dispatch, currentUser]);
  // STYLES
  const userStyles = {
    "--background": `url(${user.background})`,
    "--firstColor": hexToRGBA(user.mainColor, 0.6),
    "--secondColor": hexToRGBA(user.mainColor, 0.8),
    "--thirdColor": user.mainColor,
    "--fontColor": user.fontColor,
    "--tasksColor": css.tasksColor,
    "--goalsColor": css.goalsColor,
    "--milestonesColor": css.milestonesColor,
  };
  // RENDER
  return (
    <Styles.Provider value={userStyles}>
      <div className="appContainer" style={{ ...userStyles }}>
        {user.id === null ? <Login /> : <Calendar />}
      </div>
    </Styles.Provider>
  );
};
// INITIALIZE
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
