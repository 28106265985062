// IMPORTS
import { createStore } from "redux";
import {
  userTemplate,
  itemsTemplate,
  editorTemplate,
  gridDays,
} from "./variables";
// STATE
const initialState = {
  user: userTemplate(null, null),
  days: { selected: null, all: gridDays },
  items: itemsTemplate,
  editor: editorTemplate,
};
// REDUCER
function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_USER":
      return {
        ...state,
        user: payload,
      };
    case "SET_DAYS":
      return {
        ...state,
        days: payload,
      };
    case "SET_ITEMS":
      return {
        ...state,
        items: payload,
      };
    case "SET_EDITOR":
      return {
        ...state,
        editor: payload,
      };
    case "SET_EDITOR_MANAGE":
      return {
        ...state,
        editor: {
          ...state.editor,
          manage: payload,
        },
      };
    default:
      return state;
  }
}
// ACTIONS
const setUser = (payload) => ({
  type: "SET_USER",
  payload,
});
const setDays = (payload) => ({
  type: "SET_DAYS",
  payload,
});
const setItems = (payload) => ({
  type: "SET_ITEMS",
  payload,
});
const setEditor = (payload, managing) => {
  return managing === undefined
    ? {
        type: "SET_EDITOR",
        payload,
      }
    : {
        type: "SET_EDITOR_MANAGE",
        payload,
      };
};
// STORE
const store = createStore(reducer, initialState);
export { store, setUser, setDays, setItems, setEditor };
