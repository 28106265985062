// IMPORTS
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { db, storage } from "../helpers/firebase";
import { userTemplate, colors } from "../helpers/variables";
import Header from "../components/Header";
import Background from "../assets/backgrounds/rainbow.jpg";
// COMPONENT
const Settings = ({ generateItems }) => {
  // STATE
  const user = useSelector((state) => state.user);
  const editor = useSelector((state) => state.editor);
  const defaultUser = userTemplate(null, null);
  const [background, setBackground] = useState(null);
  const [colorOptionSelected, setColorOptionSelected] = useState("main");
  // GENERATE RESET BUTTON
  const generateResetButton = (type) => {
    return (
      <div
        className="settingsResetButton"
        style={{
          right: type === "main" && "unset",
          left: type === "main" && "0rem"
        }}
        onClick={() => {
          if (type === "background") {
            setBackground(Background);
            storage
              .ref(`backgrounds/${user.id}`)
              .delete()
              .then(() => {
                db.collection("users").doc(user.id).update({
                  background: Background,
                });
              })
              .catch((error) => {
                console.log("BACKGROUND DELETE ERROR >>", error);
              });
          } else {
            db.collection("users")
              .doc(user.id)
              .update({
                [`${type}Color`]: defaultUser[`${type}Color`],
              });
          }
        }}
      >
        Reset {type !== "background" && type}
      </div>
    );
  };
  // GENERATE TITLE
  const generateTitle = (title) => {
    return (
      <div className="settingsTitleContainer">
        {title === "Background" &&
          user.background !== Background &&
          generateResetButton("background")}
        {title === "Colors" &&
          user.mainColor !== defaultUser.mainColor &&
          generateResetButton("main")}
        {title === "Colors" &&
          user.fontColor !== defaultUser.fontColor &&
          generateResetButton("font")}
        <p className="settingsTitle">{title}</p>
      </div>
    );
  };
  // RENDER
  return (
    <div
      className="settingsContainer"
      style={{
        display: editor.settings.show ? "flex" : "none",
      }}
    >
      {/* HEADER */}
      <Header headerType={"settings"} />
      {/* BACKGROUND */}
      {editor.settings.type === "background" && (
        <div className="settingsBackgroundContainer">
          {generateTitle("Background")}
          <img
            className="settingsBackgroundImg"
            alt="Selected Background"
            src={background === null ? user.background : background}
          />
          <label className="settingsBackgroundLabel" htmlFor="backgroundInput">
            Select File
            <input
              style={{ display: "none" }}
              id="backgroundInput"
              type="file"
              onChange={(event) => {
                setBackground(URL.createObjectURL(event.target.files[0]));
                storage
                  .ref(`backgrounds/${user.id}`)
                  .put(event.target.files[0])
                  .then((snapshot) => snapshot.ref.getDownloadURL())
                  .then((url) => {
                    db.collection("users")
                      .doc(user.id)
                      .update({
                        ...user,
                        background: url,
                      });
                  })
                  .catch((error) => {
                    console.log("BACKGROUND UPLOAD ERROR >>", error);
                  });
              }}
            />
          </label>
        </div>
      )}
      {/* COLORS */}
      {editor.settings.type === "colors" && (
        <div className="settingsColorsContainer">
          {generateTitle("Colors")}
          {/* OPTIONS */}
          <div className="settingsColorsOptionsContainer">
            {["main", "font"].map((option, key) => {
              return (
                <div
                  key={key}
                  className={
                    colorOptionSelected === option
                      ? "settingsColorsOptionSelected"
                      : "settingsColorOption"
                  }
                  style={{
                    borderTop: option === "font" && "none",
                  }}
                  onClick={() => {
                    setColorOptionSelected(option);
                  }}
                >
                  {option}
                </div>
              );
            })}
          </div>
          {/* PICKER */}
          <div className="settingsColorPickerContainer">
            {colors.map((colorColumns, firstKey) =>
              colorColumns.map((color, secondKey) => {
                return (
                  <div
                    key={secondKey}
                    className="settingsColorPicker"
                    style={{
                      background: color,
                      top: `${secondKey}0%`,
                      left: `calc(${firstKey}0% / 2)`,
                    }}
                    onClick={() => {
                      db.collection("users")
                        .doc(user.id)
                        .update({
                          [`${colorOptionSelected}Color`]: color,
                        })
                        .catch((error) => {
                          console.log("COLOR SAVE ERROR >>", error);
                        });
                    }}
                  >
                    <p
                      className="settingsColorPickerText"
                      style={{
                        fontSize:
                          user[`${colorOptionSelected}Color`] === color
                            ? "2rem"
                            : "0",
                        transition:
                          user[`${colorOptionSelected}Color`] === color
                            ? "0.2s ease"
                            : "0.4s ease",
                      }}
                    >
                      ✓
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      {/* TRASH */}
      {editor.settings.type === "trash" && (
        <div className="settingsTrashContainer">
          {generateTitle("Trash")}
          {generateItems("trash", null, "trash", true)}
        </div>
      )}
    </div>
  );
};
// EXPORT
export default Settings;
